import { mapGetters, mapActions } from 'vuex'
import { currencyFormat } from '@/utils'
export default {
  name: 'events',
  data: () => ({
    eventType: ['Upcoming Events', 'Past Events'],
    eventList: [],
    listOfDisabledPath: ['g2-roadshow-upn', 'g2-roadshow-usd', 'g2-roadshow-uad', 'g2-roadshow-umby'],
    isFetchingData: false,
    currentPage: 0,
    activeEvent: 0,
    keyword: '',
    totalData: 0
  }),
  filters: {
    currencyFormat
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  components: {
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    Search: () => import(/* webpackChunkName: "button" */ '@/components/icons/Search'),
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField'),
    ProductCard: () => import(/* webpackChunkName: "image" */ '@/components/card/ProductCard'),
    Cash: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Cash'),
    Calendar: () => import(/* webpackChunkName: "icon" */ '@/components/icons/CalendarNumber'),
    Location: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Location'),
    Time: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Time'),
    Close: () => import(/* webpackChunkName: "button" */ '@/components/icons/Close'),
    Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/pagination/Pagination')
  },
  computed: {
    ...mapGetters('common', ['isMobile']),
    setCurrentPage() {
      return this.currentPage + 1
    },
    maxNameSize() {
      if (this.isMobile) return 90
      return 95
    },
    pageSize() {
      return this.isMobile ? 3 : 9
    },
    tabEventCategory() {
      if (this.activeEvent === 0) return 'NEWEST'
      return 'PASSED'
    },
    isSearchResultEmpty() {
      if (this.keyword !== '' && this.eventList.length === 0) return true
      return false
    }
  },
  mounted() {
    this.getAllEvent(true)
  },
  methods: {
    ...mapActions('program', ['getEventList']),
    clickEventTab(index) {
      if (index === this.activeEvent) return
      this.activeEvent = index
      this.getAllEvent(true)
    },
    toEventDetailPage(slug) {
      this.$router.push(`/event/${slug}`)
    },
    onPageChange(page) {
      this.currentPage = page - 1
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      this.getAllEvent(true)
    },
    getAllEvent(isFillData) {
      this.isFetchingData = true
      if (isFillData) {
        this.eventList = new Array(3)
        const item = {
          url: '',
          type: '',
          title: '',
          date: '',
          time: '',
          place: '',
          price: ''
        }

        this.eventList.fill(item)
      }

      this.getEventList({
        params: {
          page: this.currentPage,
          size: this.pageSize,
          name: this.keyword,
          sort_by: 'createdDate',
          tabEventCategory: this.tabEventCategory
        }
      }).then(
        (response) => {
          if (response.data.length === 0) {
            this.eventList = []
          } else {
            this.eventList = []
            this.totalData = response.totalRows
            response.data.map((event) => {
              // disable event offline in campus
              if (this.listOfDisabledPath.includes(event.coreClass.slug)) return
              this.eventList.push({
                title: event.coreClass.name,
                type: event.coreClass.categoryText,
                date: event.coreClass.scheduleFrom,
                time: event.coreClass.schedule,
                place: event.coreClass.location,
                price: event.coreClass.price,
                salePrice: event.coreClass.salePrice,
                url: event.coreClass.url1,
                slug: event.coreClass.slug,
                bilingualInEnglish: event.coreClass.content[0]
              })
            })
          }
          this.isFetchingData = false
        },
        () => { }
      )
    },
    clearKeyword() {
      this.keyword = ''
      this.getAllEvent()
    }
  }
}
